<template>
  <div>
    <v-app id="inspire">
      <v-data-table
        :headers="headers"
        :items="tableData"
        :hide-default-footer="isPagination ? false : true"
        class="elevation-1"
        :items-per-page="isPagination ? customersPerPageItem : tableData.length"
        :mobile-breakpoint="0"
      >

        <template v-slot:item.origin="{ item }">
          <origin-destination-dropdown
            :name="item.origin"
            :options="item.subCity.origin"
          />
        </template>

        <template v-slot:item.destination="{ item }">
          <origin-destination-dropdown
            :name="item.destination"
            :options="item.subCity.destination"
          />
        </template>

        <template v-slot:item.pricingMethod="{ item }">
          <div v-if="!isCustomer">
            {{  item.pricingMethodTypeName  }}
          </div>
        </template>

        <template v-slot:item.value="{ item }">
          <div v-if="!isCustomer">
              <price-value-dropdown :item="item" />
          </div>
        </template>

        <!-- Slot for actions -->
        <template v-slot:item.action>
          <div class="item-row">
            -
          </div>
        </template>

        <template v-slot:item.createdAt="{item}">
          <div v-if="isCustomer">
            <date-format
              :date="item.createdAt"
            />
          </div>
          <div v-else>
            {{ item.createdAt }}
          </div>
        </template>

        <template v-slot:item.updatedAt="{item}">
          <div v-if="isCustomer">
            <date-format
              :date="item.updatedAt"
            />
          </div>
          <div v-else>
            {{ item.updatedAt }}
          </div>
        </template>
       
      </v-data-table>
    </v-app>
  </div>
</template>

<script>
import PriceValueDropdown from "./PriceValueDropdown.vue";
import DateFormat from "@/views/components/DateFormat.vue";
import { tablePaginationPerPageItem } from "@/helpers/utility";
import OriginDestinationDropdown from "./OriginDestinationDropdown.vue";

export default {
  bodyClass: "landing",
  components: {
    PriceValueDropdown,
    DateFormat,
    OriginDestinationDropdown
  },
  data: function() {
    return {
      customersPerPageItem: tablePaginationPerPageItem.customers,
    };
  },
  props: {
    // Component props
    tableData: {
      type: Array
    },
    isCustomer: {
      type: Boolean
    },
    isPagination: {
        type: Boolean,
    },
    headers: {
      type: Array
    }
  },
  methods: {}
};
</script>

<style></style>
