<template>
  <div>
    <v-app 
      id="inspire"
      class="d-none d-md-block"
    >
      <v-data-table
        :headers="headers"
        :items="groupedTableData"
        :expanded.sync="expandedItems"
        :hide-default-footer="true"
        item-key="name"
        select-enable
        class="elevation-1"
        :items-per-page="filteredTableData.length"
        :mobile-breakpoint="0"
      >
        <template v-slot:item.name="{ item }">
          <div v-if="item.action">
            <div v-if="!hasDataTypeFields(item)">
              <v-icon
                @click="toggleLaneRow(item)"
                :class="[
                  'expand-icon-tariff',
                  expandedItems.includes(item)
                    ? 'mdi-chevron-up'
                    : 'mdi-chevron-down'
                ]"
              >
                {{
                  expandedItems.includes(item)
                    ? "mdi-chevron-up"
                    : "mdi-chevron-down"
                }}
              </v-icon>
            </div>

            <!-- when  hasDataTypeFields is true-->
              <div v-else>
                {{ item.name || 'No Name Available' }}
              </div>
            </div>
            <div v-else>
              {{ item.name }}
            </div>
        </template>
  
        <!-- Expanded row content (details) -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="sub-lane-data-table">
            <v-simple-table>
              <template v-slot:default>
              
                <div v-if="item.children">
                  <current-tariff-tables
                    :headers="headers"
                    :key="'expanded-' + item.name"
                    :tableData="item.children"
                    :expanded.sync="expandedItems"
                  />
                </div>
              
              </template>
            </v-simple-table>
          </td>
        </template>
  
        <template v-slot:item.origin="{ item }">
          <origin-destination-dropdown
            :name="item.origin"
            :options="item.subCity.origin"
          />
        </template>

        <template v-slot:item.destination="{ item }">
          <origin-destination-dropdown
            :name="item.destination"
            :options="item.subCity.destination"
          />
        </template>

        <template v-slot:item.pricingMethod="{ item }">
          {{ item.pricingMethodTypeName }}
        </template>
        
        <template v-slot:item.value="{ item }">
          <price-value-dropdown :item="item" />
        </template>

        <!-- Slot for actions -->
        <template v-slot:item.action>
          <div class="item-row">
            -
          </div>
        </template>

        <!-- accessorials -->
        <template v-slot:item.dataTypeFields="{ item }">
          <div class="font-bold mb-1 mt-1">
            <a
              class="btn-link view-all-link-btn mb-2"
              @click="viewAllDataTypeFields(item)"
            >
              View all
            </a>
          </div>
        </template>
      </v-data-table>

      <view-all-data-type-fields-modal
        ref="viewAllDataTypeFieldsModal"
        :selectedItem="selectedItem"
      />
    </v-app>
  </div>
</template>
  
<script>
import CurrentTariffTables from './CurrentTariffTables.vue';
import PriceValueDropdown from './PriceValueDropdown.vue';
import ViewAllDataTypeFieldsModal from './ViewAllDataTypeFieldsModal.vue';
import OriginDestinationDropdown from "./OriginDestinationDropdown.vue";

export default {
  name: "",
  bodyClass: "carriers",
  components: {
      CurrentTariffTables,
      PriceValueDropdown,
      ViewAllDataTypeFieldsModal,
      OriginDestinationDropdown
  },
  computed: {
    groupedTableData() {
      return this.groupedLaneItems(this.filteredTableData);
    }
  },
  props: {
    // Component props
    headers: {
      type: Array,
    },
    filteredTableData: {
      type: Array,
    }
  },
  data() {
    return {
      expandedItems: [],
      selectedItem: {},
    }
  },
  methods: {
    // Method to check for dataTypeFields within item or its children
    hasDataTypeFields(item) {
      if (item.dataTypeFields) {
        return item.dataTypeFields && item.dataTypeFields.length > 0;
      } else if(item.discount) {
        return true;
      }
    },
    /**
     * Opens a modal to view all data type fields of a selected item.
     *
     * @param {Object} item - The selected item to view data type fields for.
     */
    viewAllDataTypeFields(item) {
      this.selectedItem = item;
      this.$refs.viewAllDataTypeFieldsModal.openModal();
    },
    /**
     * Toggle the expansion state of a table row.
     * @param {Object} item - The item to be toggled.
     */
    toggleLaneRow(item) {
      const index = this.expandedItems.indexOf(item);
      if (index === -1) {
        this.expandedItems.push(item);
      } else {
        this.expandedItems.splice(index, 1);
      }
    },
    /**
     * Groups the provided items by origin and destination
     *
     * @param {Array} items - The array of items to be grouped.
     * 
     * @returns {Array} - The grouped data as an array, where each entry represents a group or a single ungrouped item.
     */
    groupedLaneItems(items) {
      const groupedData = {};
      // Iterate through the data and group by origin and destination, unless dataTypeFields is present
      items.forEach(item => {
      // If the item has dataTypeFields, it should remain ungrouped
      if (item.dataTypeFields && item.dataTypeFields.length > 0) {
        groupedData[item.name] = item;  // Keep the item unchanged
    
        this.selectedItem = item;
        return; // Skip further processing for this item
      }

      if (item.discount) {
        groupedData[item.name] = item;  // Keep the item unchanged
        return; // Skip further processing for this item
      }

      const key = `${item.origin} to ${item.destination}`;
      if (!groupedData[key]) {
        groupedData[key] = {
          ...item,
          label: key,
          children: [],
          expanded: false,
          
        };
      }
      groupedData[key].children.push(item);
    });

    // Convert the groupedData object into an array
    const laneGroupedData = Object.values(groupedData);

    laneGroupedData.forEach(entry => {
      if (entry.children && entry.children.length === 1) {
        // Only one record with the same origin and destination, remove the "children" key
        delete entry.children;
      }

      if (entry.children && entry.children.length > 1) {
        entry.name = "";
        entry.createdAt = "";
        entry.pricingMethod = "";
        entry.subCity = {
          origin: [],
          destination: []
        };
        entry.value = null;
        entry.action = true;
        entry.pricingMethodTypeName = "";
      }
    });

    return laneGroupedData;
    }
  }
}
</script>

<style>
  .view-all-link-btn {
    color: #1976d2 !important;
    text-decoration: underline !important;
  }
</style>