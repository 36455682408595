<template>
  <ValidationObserver v-slot="{ validate }" ref="formValidator">
    <form
      class="needs-validation"
      @submit.prevent="validate().then(checkFormValidate)"
    >
      <div class="chargeable-weight-card">
        <chargeable-weight-card
          :selectedTab="selectedTab"
          :weightDetail="weightDetail"
        />
      </div>
      <div class="shipment-quote-card section-c">

          <div class="margin-top-bottom shipment-details-summary-title">
            Shipment Details Summary
          </div>
          
          <div class="shipment-details-scroll">
            <div v-if="userIsBroker">
              <div class="form-group has-label">
                <label> Client Name </label>
                <div class="input-v1">
                  <ValidationProvider
                    rules="required"
                    name="Client Name"
                    v-slot="{ errors, valid, invalid, validated }"
                  >
                    <vue-basic-autocomplete
                      trackby="name"
                      :input-class="
                        valid && validated
                          ? 'form-control is-valid'
                          : invalid && validated
                          ? 'form-control is-invalid'
                          : 'form-control'
                      "
                      placeholder="Select a client"
                      none-find="Client not found"
                      v-model="clientName"
                      :options="clientList"
                      @selected="(event) => changeClient(event)"
                      @input="(event) => changeClientInputValue(event)"
                    />
                    <span class="validate-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group has-label">
                <label> Quote Contact </label>
                <div class="input-v1">
                  <ValidationProvider
                    rules="required"
                    name="Quote Contact"
                    v-slot="{ errors }"
                  >
                    <multiselect
                      placeholder="Select a contact"
                      class="contact-placeholder-shipment"
                      :multiple="true"
                      v-model="quoteContact"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :options="contactList"
                    >
                    </multiselect>
                    <span class="validate-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group has-label input-v1 margin-shipment">
                <label> Margin {{ marginDollar ? "$" : "%" }}</label>
                <ValidationProvider
                  rules="required"
                  name="Margin"
                  v-slot="{ errors, valid, invalid, validated }"
                  v-if="marginDollar"
                >
                  <input
                    type="number"
                    step="0.01"
                    min="50"
                    :class="[
                      { 'is-valid': valid && validated },
                      { 'is-invalid': invalid && validated },
                      'form-control',
                    ]"
                    placeholder="00.00"
                    v-model="margin"
                    @wheel="handleWheelEvent"
                    @keydown="handleKeydownEvent"
                  />
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  name="Margin"
                  v-slot="{ errors, valid, invalid, validated }"
                  v-else
                >
                  <input
                    type="number"
                    step="0.01"
                    :class="[
                      { 'is-valid': valid && validated },
                      { 'is-invalid': invalid && validated },
                      'form-control',
                    ]"
                    placeholder="00.00"
                    v-model="margin"
                    @wheel="handleWheelEvent"
                    @keydown="handleKeydownEvent"
                  />
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="input-group-append margin-input-cal-edit input-v1">
                  <span class="input-group-text">
                    <span
                      class="margin-toggle-arrow"
                      v-on:click="marginToggle"
                    >
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.1656 5.81608L5.65059 5.81608" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.75 2.41699C12.75 2.41699 16.1667 4.22449 16.1667 5.81533C16.1667 7.40533 12.75 9.21366 12.75 9.21366" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3.81043 14.0885L14.3254 14.0885" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.22633 17.4881C7.22633 17.4881 3.80967 15.6797 3.80967 14.0897C3.80967 12.4997 7.22633 10.6914 7.22633 10.6914" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </span>
                    <div class="circle-margin">
                      <span v-if="marginDollar" class="margin-percentage-dollar">
                        %
                      </span>
                      <span v-else class="margin-percentage-dollar">
                        $
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group has-label input-v1">
              <label> Origin </label>
              <div class="form-group">
                <vue-google-autocomplete
                  ref="originShipmentAddress"
                  id="origin-shipment-map"
                  classname="form-control"
                  placeholder="City - Postal code"
                  v-model="origin"
                  v-on:placechanged="originPlaceChanged"
                  types="(regions)"
                  @keyup="(event) => onKeyUpAutocomplete(event, true)"
                  :country="country"
                >
                </vue-google-autocomplete>
                <span v-if="originInvalid" class="validate-error">The Origin field is required</span>
              </div>
            </div>
            <div class="text-center">
              <svg 
              @click.prevent="toggleOriginDestination"
              class="c-p"
              width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.1656 5.81608L5.65059 5.81608" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.75 2.41699C12.75 2.41699 16.1667 4.22449 16.1667 5.81533C16.1667 7.40533 12.75 9.21366 12.75 9.21366" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.81043 14.0885L14.3254 14.0885" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.22633 17.4881C7.22633 17.4881 3.80967 15.6797 3.80967 14.0897C3.80967 12.4997 7.22633 10.6914 7.22633 10.6914" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="form-group has-label input-v1">
              <label> Destination </label>
              <div class="form-group">
                <vue-google-autocomplete
                  ref="destinationShipmentAddress"
                  id="destination-shipment-map"
                  classname="form-control"
                  placeholder="City - Postal code"
                  v-model="destination"
                  v-on:placechanged="destinationPlaceChanged"
                  types="(regions)"
                  @keyup="(event) => onKeyUpAutocomplete(event, false)"
                  :country="country"
                >
                </vue-google-autocomplete>
                <span v-if="destinationInvalid" class="validate-error">The Destination field is required</span>
              </div>
            </div>

            <additional-piece-view
              ref="additionalPiece"
              :createQuote="false"
              :additionalPieceItem="additionalPieceItem"
              :selectedTab="selectedTab"
              :nonStackable="nonStackable"
              @change-tab-name="changeTabName"
              @weight-detail="updateWeightDetail"
              @update-pickup-delivery-date="updatePickupDeliveryDate" 
            />

            <el-dropdown ref="accessorialsDropdown" trigger="click">
              <base-button
                type="success"
                class="clear-all-btn mr-2"
              >
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4668 4C8.04852 4 4.4668 7.58172 4.4668 12C4.4668 16.4183 8.04852 20 12.4668 20C16.8851 20 20.4668 16.4183 20.4668 12C20.4668 7.58172 16.8851 4 12.4668 4ZM2.4668 12C2.4668 6.47715 6.94395 2 12.4668 2C17.9896 2 22.4668 6.47715 22.4668 12C22.4668 17.5228 17.9896 22 12.4668 22C6.94395 22 2.4668 17.5228 2.4668 12Z" fill="black"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4668 7C13.0191 7 13.4668 7.44772 13.4668 8V11H16.4668C17.0191 11 17.4668 11.4477 17.4668 12C17.4668 12.5523 17.0191 13 16.4668 13H13.4668V16C13.4668 16.5523 13.0191 17 12.4668 17C11.9145 17 11.4668 16.5523 11.4668 16V13H8.4668C7.91451 13 7.4668 12.5523 7.4668 12C7.4668 11.4477 7.91451 11 8.4668 11H11.4668V8C11.4668 7.44772 11.9145 7 12.4668 7Z" fill="black"/>
                </svg>
                Accessorials
              </base-button>

              <el-dropdown-menu slot="dropdown" class="accessorials-el-dropdown-menu">
                <div>
                  <div class="row">
                    <div class="col-md-4">
                      <p class="accessorials-title">Pick up</p>
                      <div
                        v-for="(item, index) in pickupAccessorials"
                        :key="index"
                      >
                        <base-checkbox
                          class="mb-2"
                          v-model="item.checked"
                          :checked="item.checked"
                        >
                          {{ item.name }}
                        </base-checkbox>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <p class="accessorials-title">Delivery</p>
                      <div
                        v-for="(item, index) in deliveryAccessorials"
                        :key="index"
                      >
                        <base-checkbox
                          class="mb-2"
                          v-model="item.checked"
                          :checked="item.checked"
                        >
                          {{ item.name }}
                        </base-checkbox>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <p class="accessorials-title">Other</p>
                      <div
                        v-for="(item, index) in otherAccessorials"
                        :key="index"
                      >
                        <base-checkbox
                          class="mb-2"
                          v-model="item.checked"
                          :checked="item.checked"
                          @input="selectNonStackableAccessorial($event, item)"
                        >
                          {{ item.name }}
                        </base-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="accessorials-done-btn">
                    <base-button 
                      type="primary" class="get-rate-btn"
                      @click="handleDoneClick"
                    >
                      DONE
                    </base-button>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>

            <div class="chip-wrap">
              <div v-for="(item, index) in accessorialsCheckArray" :key="index">
                <div
                  v-if="item.checked"
                  class="chip"
                  :style="{ backgroundColor: item.backgroundColor }"
                >
                  {{ item.name }}
                  <span class="closebtn" @click="removeChip(index, item)"
                    >&times;</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="shipment-detail-footer-btn">
            <button
              type="submit"
              class="btn btn-default btn-theme-FFD75E mr-2"
              @click="checkFormValidate('update')"
            >
              Update
            </button>
            <button
              v-if="hideSaveQuoteBtn"
              type="submit"
              :disabled="disableSaveQuoteBtn"
              @click="checkFormValidate('save')"
              class="btn btn-default btn-theme-24876E btn-primary save-quote-btn"
            >
              Save
            </button>
          </div>
        </div>
    </form>
  </ValidationObserver>
</template>

<script>
import Vue from "vue";
import AdditionalPieceView from "@/views/quotes/AdditionalPieceView";
import ChargeableWeightCard from "@/views/quotes/ChargeableWeightCard";
import Multiselect from "vue-multiselect";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapGetters } from "vuex";
import { API, masterAPI } from "@/api/API";
import { setClientList, formatToLocalDateTime } from "@/helpers/utility";
import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";

export default {
  components: {
    ChargeableWeightCard,
    AdditionalPieceView,
    Multiselect,
    VueGoogleAutocomplete,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  data: function() {
    return {
      // Component data properties
      selected: 0,
      country: ["us", "ca"],
      clientName: "",
      quoteContact: [],
      margin: "",
      destination: "",
      origin: "",
      additionalPieceItem: [],
      clientList: [],
      contactList: [],
      marginDollar: false,
      selectedTab: "lbs",
      pickupAccessorials: [],
      deliveryAccessorials: [],
      otherAccessorials: [],
      accessorialsCheckArray: [],

      originShipmentAddress: "",
      destinationShipmentAddress: "",
      originInvalid: false,
      destinationInvalid: false,
      // Save quote details
      selectedClientId: "",
      selectedContacts: [],
      selectedRates: [],
      oldClientName: "",
      totalVolumetricWeight: "",
      selectedQuotes: [],
      hideSaveQuoteBtn: true,
      nonStackable: false,
      shipmentLoader: false,
      weightDetail: {
        totalChargeableWeight: '',
        totalVolumetricWeight: '',
        totalWeight: '',
        shipmentLoader: false,
      },
      pickupDate: "",
      deliveryDate: ""
    };
  },
  // Component props
  props: ["disableSaveQuoteBtn", "selectedQuote"],
  watch: {
     /**
     * Watcher for the selectedQuote property.
     */
    selectedQuote: {
      handler: function(value) {
        this.selectedQuotes = value;
      },
      immediate: true,
    },
    client: {
      handler(newClient) {
        this.clientList = setClientList(newClient);
      },
      immediate: true,
    },
    userIsBroker: {
      handler() {
        this.setShipmentDetailSummary();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["client", "userIsBroker"]),
  },
  methods: {
    /**
     * Toggles the margin type between dollar and percentage.
     */
    marginToggle() {
      this.marginDollar = !this.marginDollar;
    },
    /**
     * Changes the tab name and updates the additional piece item list.
     * @param {Array} data - The updated additional piece item list.
     * @param {string} tab - The newly selected tab name.
     */
    changeTabName(data, tab) {
      this.selectedTab = tab;
      this.additionalPieceItem = data;
      this.$nextTick(() => {
        this.$refs.additionalPiece.chargeableWeightApi();
      });
    },
    updateWeightDetail(data) {
      this.weightDetail = data;
    },
    /**
     * Selects or deselects an NonStackable accessorial item.
     * @param {boolean} e - The selection state.
     * @param {Object} item - The selected accessorial item.
     */
    selectNonStackableAccessorial(e, item) {
      if (item.key == "nonStackable") {
        this.nonStackable = e;
      }
    },
    /**
     * Finds rates using the Find Rates API.
     */
    async findRates() {
      let additionalPieceList = this.additionalPieceItem;
      this.$emit("show-loader", true);
      let items = [];
      let updatedOrigin = localStorage.origin;
      let updatedDestination = localStorage.destination;

      additionalPieceList.map((item) => {
        items.push({
          quantity: item.quantity,
          length: item.length,
          width: item.width,
          height: item.height,
          weight: item.weight,
          nmfc: item.nmfc,
          sub: item.sub,
          freightClass: item.freightClass
        });
      });

      // Accessing the pickupDate and deliveryDate
      let pickupDate = this.$refs.additionalPiece.pickupDeliveryDate.pickupDate;
      let deliveryDate = this.$refs.additionalPiece.pickupDeliveryDate.deliveryDate;
      
      let bodyPayload = {
        origin: updatedOrigin,
        destination: updatedDestination,
        metric: {
          volume: this.selectedTab == "lbs" ? "inches" : "cms",
          weight: this.selectedTab,
        },
        items: items,
        accessorials: this.accessorialsList(),
        nonStackable: this.nonStackable,
      };

      // Add metadata conditionally only if both pickupDate and deliveryDate are available
      if (pickupDate && deliveryDate) {

        // Format the dates using moment.js and the utility function
        let formattedPickupDate = formatToLocalDateTime(pickupDate);
        let formattedDeliveryDate = formatToLocalDateTime(deliveryDate);

        // Assign to the payload
        bodyPayload.metadata = {
          pickupDate: formattedPickupDate,
          deliveryDate: formattedDeliveryDate,
        };
      }

      // Conditionally add clientId, contact, and margin based on userIsBroker
      if (this.userIsBroker) {
        bodyPayload.clientId = this.clientName.id;
        bodyPayload.contact = this.getContactByName();
        bodyPayload.margin = {
          type: this.marginDollar ? "fixed" : "percentage",
          value: this.margin,
        };
      }

      let response = await masterAPI(
        API.API_ENDPOINT.findLanes,
        API.API_METHOD.post,
        undefined,
        JSON.stringify(bodyPayload)
      );

      if (response.status == 200) {
        this.$session.remove('save_quote_details');
        let shipmentMetrics = response.data.shipmentMetrics;

        let quoteDetail = {
          clientName: this.clientName,
          quoteContact: this.quoteContact,
          margin: this.margin,
          origin: updatedOrigin,
          destination: updatedDestination,
          addShipmentItem: shipmentMetrics.items,
          selectedTab: shipmentMetrics.metric.weight,
          marginDollar: this.marginDollar,
          response: response.data,
          pickupAccessorials: this.pickupAccessorials,
          deliveryAccessorials: this.deliveryAccessorials,
          otherAccessorials: this.otherAccessorials,
          quoteRequest: response.data.quoteRequest,
        };
        if (pickupDate && deliveryDate) {
          let formattedPickupDate = formatToLocalDateTime(pickupDate);
          let formattedDeliveryDate = formatToLocalDateTime(deliveryDate);
          quoteDetail.metadata = {
            pickupDate: formattedPickupDate,
            deliveryDate: formattedDeliveryDate,
          };
        }

        this.updateShipmentMetrics(shipmentMetrics.items);

        this.selectedRates = response.data.rates;
        this.$refs.additionalPiece.chargeableWeightApi();
        this.totalVolumetricWeight = quoteDetail.response.totalVolumetricWeight;

        this.$emit("quote-detail", JSON.stringify(quoteDetail));
        this.$session.set("save_quote_details", JSON.stringify(quoteDetail));
        let vm = this;
        setTimeout(function() {
          vm.$emit("show-loader", false);
        }, 100);
        setTimeout(function() {
          vm.$emit("update-quote");
        }, 500);
      } else {
        this.$emit("show-loader", false);
        this.$toast.error(response.data.message.replaceAll('"', ""));
      }
    },
     /**
     * Performs form validation and triggers the save or update process based on the "when" parameter.
     * @param {string} when - The action to perform ("save" or "update").
     */
    async checkFormValidate(when) {
      // Perform form validation
      const isFormValid = await this.$refs.formValidator.validate();
      if (isFormValid) {
        if (when == "save") {
          let shipmentData = this.additionalPieceItem;
          shipmentData.forEach((item) => {
            delete item.showItem;
            delete item.originalHeight;
            delete item.originalLength;
            delete item.originalWeight;
            delete item.originalWidth;
            return item;
          });

          let additionalPieceRef = this.$refs.additionalPiece;

          let newQuoteData = {
            name: "Quote",
            details: {
              metric: {
                volume: this.selectedTab == "lbs" ? "inches" : "cms",
                weight: this.selectedTab,
              },
              items: shipmentData,
              totalWeight: additionalPieceRef.totalWeight,
              totalVolume: additionalPieceRef.totalVolume,
              totalVolumetricWeight: this.totalVolumetricWeight,
              totalChargeableWeight: additionalPieceRef.totalChargeableWeight,
              rates: null,
            },
          };

          if (this.userIsBroker) {
            let contactObj = {
              name: this.getContactByName().name,
              email: this.getContactByName().email,
              phone: this.getContactByName().phoneNumber,
            };

            newQuoteData.clientId = this.selectedClientId;
            newQuoteData.details.contact = contactObj;
            newQuoteData.details.margin = {
              type: this.marginDollar ? "fixed" : "percentage",
              value: this.margin,
            };
          }

          this.$emit("save-new-quote-data", newQuoteData);
        } else if (when == "update") {
          this.findRates();
        }
      }
    },

     /**
     * Updates the pickup and delivery dates based on the provided values.
     * @param {string} newPickupDate - The new pickup date value to set.
     * @param {string} newDeliveryDate - The new delivery date value to set.
     */ 
    updatePickupDeliveryDate(newPickupDate, newDeliveryDate) {
      this.pickupDate = newPickupDate;
      this.deliveryDate = newDeliveryDate;
    },
    /**
     * Event handler for the changeClientInputValue event. Clears the quoteContact array if the client name input value is empty.
     * @param {string} value - Client name input value.
     */
    changeClientInputValue(value) {
      if (value == "") {
        this.quoteContact = [];
      }
    },
     /**
     * Handles the selection of a client in the dropdown.
     * @param {Object} selectItem - The selected client object.
     */
     changeClient(selectItem) {
      let marginData = selectItem.margin;
      this.margin = marginData.value;
      if (marginData.type == "fixed") {
        this.marginDollar = true;
      } else {
        this.marginDollar = false;
      }

      this.contactList = [];

      selectItem.contacts.map((item) => {
        this.contactList.push(item.name);
      });

      this.selectedClientId = selectItem.id;
      this.selectedContacts = selectItem.contacts;

      if (this.oldClientName != selectItem.name) {
        this.quoteContact = [];
        this.oldClientName = selectItem.name;
      }
    },
    /**
     * Sets the shipment detail summary based on the saved quote details.
     */
    setShipmentDetailSummary() {
      let saveQuoteDetail = Vue.prototype.$session.get("save_quote_details");
      if (saveQuoteDetail) {
        this.contactList = [];
        let quoteDetail = JSON.parse(saveQuoteDetail);
        this.clientName = quoteDetail.clientName;
        this.margin = quoteDetail.margin;
        this.origin = quoteDetail.origin;
        this.destination = quoteDetail.destination;
        this.selectedTab = quoteDetail.selectedTab;
        this.marginDollar = quoteDetail.marginDollar;

        this.pickupAccessorials = quoteDetail.pickupAccessorials;
        this.deliveryAccessorials = quoteDetail.deliveryAccessorials;
        this.otherAccessorials = quoteDetail.otherAccessorials;
        let shipmentItem = quoteDetail.addShipmentItem;

        this.updateShipmentMetrics(shipmentItem);
        
        let mergeArray = [
          ...this.pickupAccessorials,
          ...this.deliveryAccessorials,
          ...this.otherAccessorials,
        ];
        this.accessorialsCheckArray = mergeArray;

        localStorage.origin = quoteDetail.origin;
        localStorage.destination = quoteDetail.destination;

        if (this.userIsBroker) {
          if (quoteDetail.clientName != "") {
            quoteDetail.clientName.contacts.map((item) => {
              this.contactList.push(item.name);
            });
            this.quoteContact = quoteDetail.quoteContact;
            this.selectedClientId = quoteDetail.clientName.id;
            this.selectedContacts = quoteDetail.clientName.contacts;
            this.oldClientName = quoteDetail.clientName.name;
          }
        }
        
        this.selectedRates = quoteDetail.response.rates;
        this.totalVolumetricWeight = quoteDetail.response.totalVolumetricWeight;

        this.otherAccessorials.map((item) => {
          if (item.key == "nonStackable") {
            this.nonStackable = item.checked;
          }
        });
      }
    },
    /**
     * Merges the pickup, delivery, and other accessorials into a single array.
     * @returns {Array} - The merged accessorials array.
     */
    accessorialsList() {
      let mergeArray = [
        ...this.pickupAccessorials,
        ...this.deliveryAccessorials,
        ...this.otherAccessorials,
      ];

      let accessorialsDetail = [];
      mergeArray.map((item) => {
        if (item.checked) {
          accessorialsDetail.push(item.key);
        }
      });
      return accessorialsDetail;
    },
     /**
     * Retrieves the contact object based on the selected contact names.
     * @returns {Object} - The contact object.
     */
    getContactByName() {
      let contact = [];
      let selectedContact = this.clientName.contacts;
      this.quoteContact.map((item) => {
        selectedContact.map((contactsObj) => {
          if (item == contactsObj.name) {
            contact.push(contactsObj);
          }
        });
      });
      let contactObj = {};
      if (contact.length != 0) {
        contactObj = contact[0];
      }
      return contactObj;
    },
    /**
     * Removes the selected accessorial chip.
     * @param {number} index - The index of the chip in the array.
     * @param {Object} item - The selected accessorial item.
     */
    removeChip(index, item) {
      this.accessorialsCheckArray[index].checked = false;
      if (item.key == "nonStackable") {
        this.nonStackable = false;
      }
    },
    /**
     * Handles the keyup event and clears the origin or destination address if the input is empty.
     * @param {Event} event - The keyup event object.
     * @param {boolean} isOrigin - Indicates whether the input is for the origin address.
     */
     onKeyUpAutocomplete(event, isOrigin) {
      if (event.target.value == "") {
        if (isOrigin) {
          this.origin = "";
          localStorage.origin = "";
        } else {
          this.destination = "";
          localStorage.destination = "";
        }
      }
    },
    /**
     * Toggles the origin and destination values and updates the corresponding input fields.
     */
    toggleOriginDestination() {
      let oldOrgin = this.origin;
      this.origin = this.destination;
      this.destination = oldOrgin;
      this.$refs.originShipmentAddress.update(this.destination);
      this.$refs.destinationShipmentAddress.update(this.origin);
    },
    /**
     * Retrieves the origin address data from the Google Autocomplete component.
     * @param {string} address - The selected address.
     * @param {Object} placeResultData - The detailed place result object.
     */
     originPlaceChanged(address, placeResultData) {
      this.origin = placeResultData.formatted_address;
      localStorage.origin = placeResultData.formatted_address;
      this.originInvalid = false;
    },
    /**
     * Retrieves the destination address data from the Google Autocomplete component.
     * @param {string} address - The selected address.
     * @param {Object} placeResultData - The detailed place result object.
     */
    destinationPlaceChanged(address, placeResultData) {
      this.destination = placeResultData.formatted_address;
      localStorage.destination = placeResultData.formatted_address;
      this.destinationInvalid = false;
    },
    handleWheelEvent(event) {
      event.preventDefault();
    },
    handleKeydownEvent(event) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
      }
    },
    /**
     * Update shipment metrics with additional piece items.
     * @param {Array<Object>} shipmentMetricsItems - Array of shipment metrics items.
    */
    updateShipmentMetrics(shipmentMetricsItems) {
      this.additionalPieceItem = [];
      
      shipmentMetricsItems.map((item) => {

        let shipmentMetricsObj = {
          quantity: item.quantity,
          length: item.length,
          width: item.width,
          height: item.height,
          weight: item.weight,
          chargeableWeight: item.chargeableWeight,
          showItem: false,
          originalLength: item.length,
          originalWidth: item.width,
          originalHeight: item.height,
          originalWeight: item.weight,
          freightClass: item.freightClass,
          nmfc: item.nmfc,
          sub: item.sub,
        };
        this.additionalPieceItem.push(shipmentMetricsObj);
      });
    },
    /**
     * Handles the click event for the "DONE" button.
     * Triggers a click event on the el-dropdown component.
     */
    handleDoneClick() {
      // Trigger the click event on the el-dropdown component using the ref
      this.$refs.accessorialsDropdown.handleClick();
    },
  },
  created() {
    this.setShipmentDetailSummary();
  },
};
</script>

<style>
.unit-toggle-label-shipment {
  position: absolute;
  top: 11px;
  right: 18px;
  font-size: 12px;
  color: #cfc7c7;
}
</style>
