import axios from 'axios';
import { Config } from "@/config/config";

export const API = {
    // API base url
    API_BASE_URL: Config.apiUrl,

    // API version
    API_VERSION: '/v1/',

    // API end point
    API_ENDPOINT: {
      // Auth API endpoint
      signup: 'auth/signup',
      login: 'auth/login',
      logout: 'auth/logout',
      resetPassword:'auth/updatePassword',
      sendResetPasswordEmail: 'auth/sendResetPasswordEmail',
      resetPasswordUsingCode: "auth/resetPasswordUsingCode",
      updateEmail:'auth/updateEmail',
      checkEmail: 'auth/checkEmail',
      user: 'auth/user',
      allUsers: 'auth/allUsers',
      allTeammatesUsers: 'auth/allTeammatesUsers',
      // Carrier API endpoint
      carrier: 'carrier',
      uploadCarrierRatesViaCsv: '/uploadCarrierRatesViaCsv',
      uploadCarrierRatesViaPdf: '/uploadCarrierRatesViaPdf',
      // Quote API endpoint
      findLanes: 'lane/findLanes',
      getQuoteRequestById: 'lane/getQuoteRequestById',
      quote: 'quote',
      team: 'team',
      quoteClient: 'quote/clients',
      // Client API endpoint
      client:'client',
      findChargeableWeight:'lane/findChargeableWeight',
      scanDimensions:'lane/scanDimensions',
      shareQuote:'/shareQuote',
	  sendQuoteToTms:'/sendQuoteToTms',
      // Accessorials API endpoint
      supportedAccessorials:'supportedAccessorials',
      addAccessorial:'addAccessorial',
      updateAccessorial:'updateAccessorial',
      // Additionals API endpoint
      supportedAdditionals:'supportedAdditionals',
      addAdditional:'addAdditional',
      updateAdditional:'updateAdditional',
      // Template API
      createTemplate:'quote/createTemplate',
      users:'user',
      dimensioner: 'dimensioner',
      inviteUser:'account/inviteUser',
      // Queued scan API
      scans: "scans",
      fillDimensions: "fillDimensions",
      customerCard: "customerCard",
      // Section API
      listSection: "listSection",
      listLanes: "listLanes/",
      listAccessorials: "listAccessorials",
      listFuelSurcharges: "listFuelSurcharges",
      listConditions: "listConditions/",
      listCustomers: "listCustomers",
      listCustomerGroups: "listCustomerGroups",
      listLanePricingMethods: "listLanePricingMethods",
      listClassDiscounts: "listClassDiscounts",
    },

    // API method
    API_METHOD: {
      post: 'POST',
      get: 'GET',
      put: 'PUT',
      delete: 'DELETE',
    },
    //
    ZIPCODE_API_URL:'https://www.zipcodeapi.com/rest/',
    ZIPCODE_API_KEY:'5uWwo9K2Yb753RyTeUJGMqoxMNbosqzAloyqj6EcgKbzhEBjJNvXILIr7XdbNUIP',
};

export const masterAPI = (endPoint, method, queryParams, bodyPayload, changeHeaders) => {
    let apiUrl = API.API_BASE_URL + API.API_VERSION + endPoint;
    let token = localStorage.getItem("id_token");

    if(queryParams) {
        apiUrl = apiUrl + '/' + queryParams;
    }

    let headers =  {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ token
    };

    if (changeHeaders) {
        headers =  {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+ token
        };
    }

    return axios(apiUrl, {
        method: method,
        headers: headers,
        data: bodyPayload
    }).then((res) => {
        return res;
    }).catch((error) => {
        if (error.response.status == 401) {
            let errorKey = error.response.data.errorKey;
            if (errorKey == "Auth.Token.Invalid" || errorKey == "Auth.User.NotFound") {
                localStorage.removeItem("id_token");
                localStorage.removeItem("p_key");
                location.reload();
            }
        }
        return error.response;
    });
}

export const autofillOriginDestinationAPI = (params) => {
    let apiUrl = API.ZIPCODE_API_URL + API.ZIPCODE_API_KEY + '/info.json/' + params + '/radians';

    return axios(apiUrl, {
        method: API.API_METHOD.get,
        headers:{
          'Content-Type': 'application/json',
        }
    }).then((res) => {
        return res;
    }).catch((error) => {
        return error.response;
    });
}
