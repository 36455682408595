<template>
  <div>
    <collapse :multiple-active="false" class="carrier-collapse">
      <div
        role="tab"
        class="card-header"
        :aria-expanded="activeCurrentTariffCollapse"
      >
        <a
          data-toggle="collapse"
          data-parent="#accordion"
          :href="`#`"
          @click.prevent="toggleCollapseCurrentTariff"
        >
          <slot name="title">
            <h5
              class="mb-0 btn btn-link w-100 text-primary text-left"
            >
              Current Tariff
              <i class="ni ni-bold-down float-right pt-1"></i>
            </h5>
          </slot>
          <i class="tim-icons icon-minimal-down"></i>
        </a>
      </div>

      <collapse-transition>
        <div
          v-show="activeCurrentTariffCollapse"
          :id="`content-6`"
          role="tabpanel"
          class="collapsed"
        >
          <div class="row align-items-center view-all-section">
            <div class="col-md-4 input-v1">
              <img class="t-search-icon" src="../../assets/img/search.png" />
              <vue-input
                alternative
                placeholder="Search Section"
                type="text"
                class="search-box"
                v-model="searchSection"
                @input="event => searchAllSection(event)"
              ></vue-input>
            </div>
            <div class="col-md-8 col-lg-4">
              <div class="">
                <div class="select-section-title">Select a Section</div>
                <choices-single
                  id="selectedSection"
                  :options="sectionFieldList"
                  :value="selectedSection"
                  v-model="selectedSection"
                  @input="event =>  selectSection(event)"
                >
                  <option value="0">Select Section</option>
                </choices-single>
              </div>
            </div>
          </div>

          <expanded-customer-group-table
            v-if="selectedSection == sectionList.customerOrCustomerGroup"
            :headers="computedHeaders"
            :tableData="searchedTableData !== null ? searchedTableData : filteredTableData"
            :isPagination="true"
          />

          <expanded-tariff-tables
            v-else
            ref="expandedTariffTables"
            :headers="computedHeaders"
            :filteredTableData="searchedTableData !== null ? searchedTableData : filteredTableData"
          />

        </div>
      </collapse-transition>
    </collapse>
  </div>
</template>

<script>
import Collapse from "@/components/Collapse/Collapse";
import { CollapseTransition } from "vue2-transitions";
import ExpandedTariffTables from "./ExpandedTariffTables.vue";
import ChoicesSingle from "@/components/SingleSelect";
import ExpandedCustomerGroupTable from "./ExpandedCustomerGroupTable.vue";
import moment from 'moment';
import { API, masterAPI } from "@/api/API";
import { sectionList, dataType, accountModuleApi, getMethodKeyByItemObject } from '@/helpers/utility';

export default {
  bodyClass: "carriers",
  components: {
    Collapse,
    CollapseTransition,
    ChoicesSingle,
    ExpandedTariffTables,
    ExpandedCustomerGroupTable
  },
  data: function() {
    return {
      activeCurrentTariffCollapse: false,
      searchSection: "",
      searchedTableData: null,
      sectionFieldList: [],
      selectedSection: sectionList.lane,
      headers: [
        // Table headers configuration
        { text: "Name", value: "name", sortable: false },
        { text: "Origin", value: "origin", sortable: false },
        { text: "Destination", value: "destination", sortable: false },
        { text: "Pricing Method", value: "pricingMethod", sortable: false },
        { text: "Value", value: "value", sortable: false },
        { text: "Created On", value: "createdAt", sortable: false },
        { text: "Updated On", value: "updatedAt", sortable: false },
        { text: "Action", value: "action", sortable: false},
      ],
      headersForOtherTariff: [
        { text: "Name", value: "name", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Data Type Fields", value: "dataTypeFields", sortable: false },
        { text: "Created On", value: "createdAt", sortable: false },
        { text: "Updated On", value: "updatedAt", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      headersForClass: [
        { text: "Name", value: "name", sortable: false },
        { text: "Discount", value: "discount", sortable: false }, // For Class, using "Discount"
        { text: "Action", value: "action", sortable: false }
      ],
      headersForCustomerGroup: [
        { text: "", value: "expandedIcon", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Created On", value: "createdAt", sortable: false },
        { text: "Updated On", value: "updatedAt", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      tableData: {},
      sectionList: sectionList,
    }
  },
  props: {
    tariffApiKey: String,
  },
  computed: {
    // Determines the headers to be used based on the selected tariff type.
    computedHeaders() {
      if (this.selectedSection === this.sectionList.lane) {
        return this.headers;
      } else if (this.selectedSection === this.sectionList.classBaseRate) {
        return this.headersForClass;
      } else if(this.selectedSection === this.sectionList.customerOrCustomerGroup) {
        return this.headersForCustomerGroup;
      } else {
        return this.headersForOtherTariff;
      }
    },

    // Filter the table data based on selectedSection
    filteredTableData() {
      return this.tableData[this.selectedSection] || [];
    }
  },
  watch: {
    tariffApiKey(newKey) {
      if (newKey) {
        this.getAllSection();
      }
    },
  },
  methods: {
    // Toggles the collapse state of the "Current Tariff" section.
    toggleCollapseCurrentTariff() {
      this.activeCurrentTariffCollapse = !this.activeCurrentTariffCollapse;
    },

    /**
     * Performs a search on the selected section field.
     * @param {string} searchValue - The search value.
     */
    searchAllSection(searchValue) {
      const searchTerm = searchValue.toLowerCase();

      // If no search term or it's less than 3 characters, clear the search and display full data
      if (!searchTerm || searchTerm.length < 3) {
        this.searchedTableData = null; // Reset to show full table data
        return;
      }

      // Filter the filteredTableData based on the search term
      const matchingData = this.filteredTableData.map(item => {
        // Check if the parent item (customer group) matches the search term
        const matchesParent = (
          (item.name && item.name.toLowerCase().includes(searchTerm)) ||
            (item.origin && item.origin.toLowerCase().includes(searchTerm)) ||
            (item.destination &&
              item.destination.toLowerCase().includes(searchTerm)) ||
          (item.value && item.value.toLowerCase().includes(searchTerm)) ||
          (item.createdAt && item.createdAt.toLowerCase().includes(searchTerm)) ||
            (item.subCity &&
              item.subCity.origin &&
              item.subCity.origin.some(
                subCityItem =>
                  subCityItem.city &&
                  subCityItem.city.toLowerCase().includes(searchTerm)
              )) ||
            (item.subCity &&
              item.subCity.destination &&
              item.subCity.destination.some(
                subCityItem =>
                  subCityItem.city &&
                  subCityItem.city.toLowerCase().includes(searchTerm)
              ))
        );

        // Filter the customers array for matching customers
        const matchingCustomers = item.customers
          ? item.customers.filter(customer =>
              customer.name.toLowerCase().includes(searchTerm) ||
              (customer.createdAt && customer.createdAt.toLowerCase().includes(searchTerm))
            )
          : [];

        // If the parent matches or any customers match, include them in the result
        if (matchesParent || matchingCustomers.length > 0) {
          return {
            ...item, // Keep the parent properties
            customers: matchingCustomers.length > 0 ? matchingCustomers : item.customers // Update customers to show only matches
          };
        }

        return null; // Exclude the group if no match is found
      }).filter(item => item !== null); // Remove null values from the final array

      // Set the searchedTableData to the filtered result
      this.searchedTableData = matchingData.length ? matchingData : [];
    },

    /**
     * Handles the selection of a tariff section in the dropdown.
     * @param {string} value - The search value.
     */
    selectSection(value) {
      if(value) {
        this.searchSection = ""; // Reset the search input to an empty string
        this.searchedTableData = null; // Set the searchedTableData to null to show the full data when the search is reset
        if (this.$refs.expandedTariffTables) {
          this.$refs.expandedTariffTables.expandedItems = []; // Reset the expanded items
        }

        if (value == this.sectionList.lane) {
          this.getAllLanes();
        } else if (value == this.sectionList.accessorial) {
          this.getAllAccessorial(value);
        } else if (value == this.sectionList.fuelSurcharge) {
          this.getAllFuelSurcharge(value);
        } else if (value == this.sectionList.conditions) {
          this.getAllCondition();
        } else if (value == this.sectionList.customerOrCustomerGroup) {
           this.getAllCustomersOrGroup();
        } else if (value == this.sectionList.classBaseRate) {
          this.listClassDiscounts();
        }
      }
    },
    /**
     * Fetches all sections and populates the section field list.
    */
    async getAllSection() {
      this.sectionFieldList = await accountModuleApi(
        API.API_ENDPOINT.listSection,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      this.getAllLanes();
    },
    /**
     * Fetches all lanes, processes them, and populates the table data.
    */
    async getAllLanes() {
      let lanesList = [];
      let response = await masterAPI(
        API.API_ENDPOINT.listLanes + this.tariffApiKey,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let listLanes = response.data;

        let lanePriceMethodList = await accountModuleApi(
          API.API_ENDPOINT.listLanePricingMethods
        );

        for (let i = 0; i < listLanes.length; i++) {
          let lane = listLanes[i];
          let type = lane.pricingMethod.type;

          let pricingMethodItem = getMethodKeyByItemObject(
            lanePriceMethodList,
            type
          );

          let pricingMethodName = pricingMethodItem.name;

          let laneDetail = {
            name: lane.name,
            origin: lane.origin,
            destination: lane.destination,
            subCity: lane.subCity,
            pricingMethod: type,
            value:
              type == dataType.fixed.toLowerCase()
                ? lane.pricingMethod.value
                : lane.pricingMethod.value.sort((a, b) => a.unit - b.unit),
            createdAt: moment(lane.createdAt).format("MMM Do, YYYY"),
            updatedAt: moment(lane.updatedAt).format("MMM Do, YYYY"),
            id: lane.id,
            edit: "",
            duplicate: "",
            pricingMethodItem: pricingMethodItem,
            pricingMethodTypeName: pricingMethodName
          };

          lanesList.push(laneDetail);
        }

        // Update the table data with processed lanes
        this.$set(this.tableData, this.selectedSection, lanesList);
      }
    },
    /**
     * Fetches all accessorial charges based on module type and populates the table data.
     * @param {string} moduleType - Type of the module to filter accessorial charges.
    */
    async getAllAccessorial(moduleType) {
      let endpoint = API.API_ENDPOINT.listAccessorials + "/"+ moduleType + "/" + this.tariffApiKey;

      let response = await masterAPI(
        endpoint,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let listAccessorial = response.data;

        const accessorialList = listAccessorial.map(accessorial => ({
          name: accessorial.name,
          description: accessorial.description,
          dataTypeFields: accessorial.dataTypeFields,
          createdAt: moment(accessorial.createdAt).format("MMM Do, YYYY"),
          updatedAt: moment(accessorial.updatedAt).format("MMM Do, YYYY"),
          id: accessorial.id,
          edit: "",
          duplicate: ""
        }));

        // Update the table data with processed accessorials
        this.$set(this.tableData, this.selectedSection, accessorialList);
      }
    },
    /**
     * Fetches all fuel surcharges based on module type and populates the table data.
     * @param {string} moduleType - Type of the module for filtering fuel surcharges.
    */
    async getAllFuelSurcharge(moduleType) {
      let endpoint = API.API_ENDPOINT.listFuelSurcharges + "/"+ moduleType + "/" + this.tariffApiKey;

      let response = await masterAPI(
        endpoint,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let listFuelSurcharge = response.data;

        const fuelSurchargeList = listFuelSurcharge.map(accessorial => ({
          name: accessorial.name,
          description: accessorial.description,
          dataTypeFields: accessorial.dataTypeFields,
          createdAt: moment(accessorial.createdAt).format("MMM Do, YYYY"),
          updatedAt: moment(accessorial.updatedAt).format("MMM Do, YYYY"),
          id: accessorial.id,
          edit: "",
          duplicate: ""
        }));

        // Update the table data with processed fuel surcharges
        this.$set(this.tableData, this.selectedSection, fuelSurchargeList);
      }
    },
    /**
     * Fetches all conditions from the API and updates the table data.
    */
    async getAllCondition() {
      let response = await masterAPI(
        API.API_ENDPOINT.listConditions + this.tariffApiKey,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        const formattedData = response.data.map(condition => {
          return {
            ...condition,
            createdAt: moment(condition.createdAt).format("MMM Do, YYYY"),
            updatedAt: moment(condition.updatedAt).format("MMM Do, YYYY")
          };
        });

        // Update table data with formatted conditions
        this.$set(this.tableData, this.selectedSection, formattedData);
      }
    },
    /**
     * Fetches all customers and customer groups, processes them, and updates the table data.
    */
    async getAllCustomersOrGroup() {
      let customerGroupsList = [];
      let customers = await this.getAllCustomerOrCustomerGroupApi(false);
      let customerGroupList = await this.getAllCustomerOrCustomerGroupApi(true);

      customerGroupList.map(async groups => {
        const filteredCustomers = this.filterCustomerObjectsById(
          customers,
          groups.customers
        );
        groups.children = filteredCustomers;

         // Remove the customers property from the groups object
        delete groups.customers;

        customerGroupsList.push(groups);
      });


      // Add the new object with key "customers" containing the customers array
      customerGroupsList.push({
        customers: customers
      });

      // Update the table data with processed customer groups
      this.$set(this.tableData, this.selectedSection, customerGroupsList);
    },
    /**
     * Fetches customer or customer group data based on the API type.
     * @param {boolean} isCustomerGroupApi - Indicates if the API call is for customer groups.
     * @returns {Promise<Array>} - The fetched customer or customer group data.
    */
    async getAllCustomerOrCustomerGroupApi(isCustomerGroupApi) {
      let apiEndPoint = isCustomerGroupApi
          ? API.API_ENDPOINT.listCustomerGroups
          : API.API_ENDPOINT.listCustomers;

      let response = await masterAPI(
          apiEndPoint + "/" + this.tariffApiKey,
          API.API_METHOD.get,
          undefined,
          undefined,
      );

      if (response.status == 200) {
        return response.data;
      } else {
        return [];
      }
    },
    /**
     * Filters an array of customer objects based on a list of customer IDs.
     * @param {Array} customerList - An array of customer objects to filter.
     * @param {Array} customerIdList - An array of customer IDs to use for filtering.
     * @returns {Array} An array of filtered customer objects based on the provided customer IDs.
     */
    filterCustomerObjectsById(customerList, customerIdList) {
      return customerList.filter(obj =>
          customerIdList.some(id => parseInt(id) === obj.id),
      );
    },
    /**
     * Fetches class discounts and updates the table data.
    */
    async listClassDiscounts() {
      let response = await masterAPI(
        API.API_ENDPOINT.listClassDiscounts,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        this.$set(this.tableData, this.selectedSection, response.data);
      }
    },
  },
  mounted() {
    document.querySelector("#selectedSection .choices__item").innerText = this.selectedSection;
  }
}
</script>

<style>
.search-box {
  border-radius: 12px;
  font-size: 15px;
  outline: 0;
  box-shadow: none;
}

.search-box input {
  padding-left: 35px;
}

.select-section-title {
  margin-top: -43px;
  margin-bottom: 6px;
  font-size: 14px;
}

.view-all-section {
  margin-top: 3rem;
}

@media only screen and (max-width: 767px) {
  .view-all-section {
    margin-top: 15px;
  }
  .select-section-title {
    margin-top: 0px;
  }
}
</style>