<template>
  <div class="origin-destination-dropdown">
    {{ name }}
    <el-dropdown trigger="click" v-if="options.length > 0">
      <i class="fa fa-caret-down ml-2" aria-hidden="true"></i>
      <el-dropdown-menu slot="dropdown">
        <div>
          <div v-for="(item, i) in options" :key="i">
            <div class="cmb-3 origin-destination-list">
              {{ item.city }}
            </div>
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";

export default {
  name: "",
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    name: {
      type: String,
    },
    options: {
      type: Array,
    },
  },
};
</script>
<style>
.origin-destination-dropdown {
    display: flex;
}

.origin-destination-dropdown i {
    cursor: pointer;
    font-size: 18px;
}
.origin-destination-list {
    font-size: 15px;
    color: black;
}
</style>
