<template>
  <div>
    <div class="mt-3 font-weight-600">
      Customer Groups
    </div>
    <v-app id="inspire">
      <v-data-table
        :headers="headers"
        :items="customerGroups"
        :expanded.sync="expandedItems"
        item-key="id"
        :hide-default-footer="isPagination ? false : true"
        class="elevation-1"
        :items-per-page="isPagination ? customerGroupsPerPageItem : tableData.length"
        :mobile-breakpoint="0"
      >
        <template v-slot:item.expandedIcon="{ item }">
          <v-icon
            v-if="item.children && item.children.length > 0"
            @click="toggleRow(item)"
            :class="['expand-icon-tariff', expandedItems.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down']"
          >
            {{ expandedItems.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </template>

        <template v-slot:item.createdAt="{item}">
          <date-format
            :date="item.createdAt"
          />
        </template>

        <template v-slot:item.updatedAt="{item}">
          <date-format
            :date="item.updatedAt"
          />
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="sub-lane-data-table">
            <v-simple-table>
              <template v-slot:default>
                <div v-if="item.children">
                  <current-tariff-tables
                    :key="item.id"
                    :tableData="item.children"
                    :isCustomer="true"
                    :isPagination="false"
                    :headers="customerHeaders"
                  />
                </div>
              </template>
            </v-simple-table>
          </td>
        </template>

      </v-data-table>
    </v-app>

    <div class="mt-3 font-weight-600">
      Customers
    </div>

    <div>  
      <current-tariff-tables
        :tableData="flattenedCustomers"
        :isCustomer="true"
        :isPagination="true"
        :headers="customerHeaders"
      />
    </div>
  </div>
</template>
  
<script>
  import DateFormat from "@/views/components/DateFormat.vue";
  import CurrentTariffTables from "./CurrentTariffTables.vue";
  import { tablePaginationPerPageItem } from "@/helpers/utility";
    
  export default {
    bodyClass: "landing",
    components: {
      DateFormat,
      CurrentTariffTables
    },
    data: function() {
      return {
        // Component's data properties
        customerHeaders: [
          { text: "Customer Name", value: "name", sortable: false },
          { text: "Created On", value: "createdAt", sortable: false },
          { text: "Updated On", value: "updatedAt", sortable: false },
          { text: "Action", value: "action", class: "custom-th-class", sortable: false }
        ],
        expandedItems: [],
        showLoader: false,
        selectedCustomerId: null,
        customerGroupsPerPageItem: tablePaginationPerPageItem.customerGroups,
      };
    },
    props: {
      // Component props
      tableData: {
        type: Array,
      },
      isPagination: {
        type: Boolean,
      },
      headers: {
        type: Array,
      }
    },
    computed: {
      customerGroups() {
        // Filter out entries that have 'customers' array
        return this.tableData.filter(group => !group.customers);
      },
      flattenedCustomers() {
        // Extract only the customers array for a separate display
        const customersEntry = this.tableData.find(entry => entry.customers);
        return customersEntry ? customersEntry.customers : [];
      }
    },
    methods: {
      /**
       * Toggle the expansion state of a table row.
       * @param {Object} item - The item to be toggled.
       */
      toggleRow(item) {
        const index = this.expandedItems.indexOf(item);
        if (index === -1) {
          this.expandedItems.push(item);
        } else {
          this.expandedItems.splice(index, 1);
        }
      }
    }
  };
</script>
  
<style>
  .expand-icon-tariff {
    cursor: pointer;
  }
</style>
  